var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-dropdown",
    {
      ref: "downdownItem",
      staticClass: "filter-dropdown",
      attrs: {
        id: "dropdown-form",
        size: "sm",
        text: _vm.filterText,
        "toggle-class": _vm.filterClass,
      },
    },
    [
      _c(
        "b-dropdown-form",
        [
          _c("b-form-textarea", {
            staticClass: "mb-2",
            attrs: {
              id: "textarea",
              placeholder: _vm.$t("SEARCH"),
              rows: "3",
              "max-rows": "6",
            },
            on: { input: _vm.updateInput },
            model: {
              value: _vm.info,
              callback: function ($$v) {
                _vm.info = $$v
              },
              expression: "info",
            },
          }),
        ],
        1
      ),
      _c("b-dropdown-divider"),
      _c(
        "b-dropdown-form",
        [
          _c(
            "b-button",
            {
              staticClass: "no-border",
              attrs: { size: "sm" },
              on: { click: _vm.closeFilter },
            },
            [_vm._v(_vm._s(_vm.$t("CLOSE")))]
          ),
          _vm.info.length != 0
            ? _c(
                "b-button",
                {
                  staticClass: "no-border",
                  attrs: { size: "sm" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("CLEAR")))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }