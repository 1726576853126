<template>
  <section>
    <div class="mt-2">
      <slot></slot
      ><b-button
        v-if="showAddRemove"
        @click="showAdd"
        size="sm"
        class="ml-2 no-border"
        ><i class="fal fa-plus"></i> {{ $t("ADD") }}</b-button
      >
    </div>
    <SdkReferensId
      show-add-remove
      :items="items"
      @removeItem="removeItem"
    ></SdkReferensId>
    <b-modal
      v-model="show"
      hide-header-close
      :dialog-class="teams.theme"
      :ok-disabled="okDisabled"
      :title="extensionCaption"
      @cancel="cancelSave"
      @ok="okSave"
      centered
      :cancel-title="$t('CANCEL')"
      :ok-title="$t('ADD')"
    >

      <b-form-group id="input-1" :label="labelCaption" label-for="input-2">
        <b-form-input trim
          v-model="label"
          aria-describedby="label-help-block"
          placeholder=""
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-2" :label="rootCaption" label-for="input-2">
        <b-form-select
          v-model="root"
          :options="options"
        ></b-form-select>
      </b-form-group>

      <b-form-group id="input-3" label-for="input-2" v-if="root != ''">
        <b-form-input trim
          v-if="showRegular"
          v-model="extension"
          placeholder=""
          aria-describedby="extension-help-block"
        ></b-form-input>
        <Pnr required v-model="extension" @valid="setValid" v-if="root == '1.2.752.129.2.1.3.1'"></Pnr>
      
        <b-form-text id="extension-help-block">{{
          extensionDescription
        }}</b-form-text>
      </b-form-group>

    </b-modal>
  </section>
</template>
<script>
import Pnr from "../../Input/Pnr.vue";
import SdkReferensId from "../../View/Information/Metadata/SdkReferensId.vue";
export default {
  components: { SdkReferensId, Pnr },
  props: {
    value: {
      type: Array,
      Default: [],
    },
    isSender: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    showAddRemove: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
  },
  data() {
    return {
      show: false,
      items: this.value,
      label: "",
      extension: "",
      root: "",
      options: [],
      valid: false
    };
  },
  methods: {
    setValid(valid)
    {
      this.valid = valid;
    },  
    showAdd() {
      this.show = true;
    },
    getExtentionName(item) {
      let filtered = this.options.filter(
        (c) => c.value == item.referenceId.extension
      );
      return filtered[0].text;
    },
    cancelSave() {
      this.clearAdd();
      this.show = false;
    },
    clearAdd() {
      this.root = "";
      this.extension = "";
      this.label = "";
    },
    okSave() {
      this.items.push({
        label: this.label,
        referenceId: {
          extension: this.extension,
          root: this.root,
        },
      });
      this.$emit("input", this.items);
      this.show = false;
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.$emit("input", this.items);
    },
  },
  computed: {
    showValid(){
      if(this.root == "")
      {
        return null;
      }
      if(this.showRegular && this.extension != "")
      {
        return true;
      }
      return false;
    },
    okDisabled() {
      if(this.root == ""){
        return true;
      }
      if(this.label == "")
      {
        return true;
      }
      if(this.extension == "")
      {
        return true;
      }
      if(!this.showRegular)
      {
        return !this.valid;
      }
      return false;
    },
    showRegular() {
      return this.root != '1.2.752.129.2.1.3.1';
    },
    selectedRootText() {
      let self = this;
      if (this.root != "") {
        let option = this.options.filter(function (item) {
          return item.value == self.root;
        })[0];
        return " (" + option.text + ")";
      }
      return "";
    },
    labelCaption() {
      return this.$t("SDK.REFERENCEID.LABEL.TITLE");
    },
    extensionCaption() {      
      if (this.isSender) {
        return this.$t("SDK.REFERENCEID.EXTENSION.SENDER.TITLE");
      }else{
        return this.$t("SDK.REFERENCEID.EXTENSION.RECIPIENT.TITLE");
      }
    },
    extensionDescription() {
      if (this.isSender) {
        return this.$t("SDK.REFERENCEID.EXTENSION.SENDER.SMALL");
      }else{
        return this.$t("SDK.REFERENCEID.EXTENSION.RECIPIENT.SMALL");
      }
    },
    rootCaption() {
      return this.$t("SDK.REFERENCEID.ROOT.TITLE");
    },
    rootDescription() {
      return this.$t("SDK.REFERENCEID.ROOT.SMALL");
    },
  },
  watch: {},
  mounted() {
    this.options.push({ value: "", text: this.$t("CHOOSE") });
    this.options.push({ value: "dnr", text: this.$t("SDK.ITEMS.dnr") });
    this.options.push({ value: "1.3.88", text: this.$t("SDK.ITEMS.1_3_88") });
    this.options.push({
      value: "1.2.752.129.2.1.3.1",
      text: this.$t("SDK.ITEMS.1_2_752_129_2_1_3_1"),
    });
    this.options.push({
      value: "1.2.752.129.2.1.3.3",
      text: this.$t("SDK.ITEMS.1_2_752_129_2_1_3_3"),
    });
    this.options.push({
      value: "1.2.752.74.9.1",
      text: this.$t("SDK.ITEMS.1_2_752_74_9_1"),
    });
    this.options.push({ value: "unregistered", text: this.$t("SDK.ITEMS.unregistered") });
  },
};
</script>
<style></style>
