<template>
  <span>
    <i class="clickable fal fa-info-square" @click="show = true"></i>
    <b-modal
      id="loa-information"
      v-model="show"
      hide-header
      :dialog-class="teams.theme"
      centered
      hide-footer
      body-class="loa-information"
    >
      <div class="text-left">
        <b>LOA - Level of assurance
            <div class="mt-2">
                {{ $t("LOA.SHORT_DESCRIPTION") }}
            </div>
            <div class="mt-2" v-if="loa != 1">
                {{ $t("LOA.APPROVED") }} 
            </div>
            <div class="mt-2">
                LOA, {{ $t("LOA.NAME") }} {{ loa }}
            </div>
        </b>
        <div class="mt-2" v-if="loa == 1">
          {{ $t("LOA.LEVEL1") }}
        </div>
        <div class="mt-2" v-if="loa != 1">
          <ul>
            <li v-if="loa == 2">{{ $t("AUTH_METHODS.sms") }}</li>
            <li v-if="loa == 2">{{ $t("AUTH_METHODS.sefos") }}</li>
            <li v-if="loa >= 2">
              {{ $t("AUTH_METHODS.se-eid") }}
              <ul>
                <li>{{ $t("AUTH_METHODS.freja") }}</li>
                <li>{{ $t("AUTH_METHODS.bankid-se") }}</li>
              </ul>
            </li>
            <li v-if="loa >= 2">{{ $t("AUTH_METHODS.siths") }}</li>
            <li v-if="loa >= 2">{{ $t("AUTH_METHODS.freja-org") }}</li>
          </ul>
        </div>
      </div>
    </b-modal>
  </span>
</template>
<script>
export default {
  props: ["loa"],
  data() {
    return {
      show: false,
    };
  },
};
</script>
<style></style>