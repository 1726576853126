<template>
  <b-dropdown
    v-if="items"
    id="dropdown-form"
    ref="downdownItem"
    class="filter-dropdown"
    size="sm"
    :text="filterText"
    :toggle-class="filterClass"
  >
    <b-dropdown-form>
      <div class="input-clear mb-1">
        <b-form-input trim :placeholder="$t('SEARCH')" v-model="searchText" @keydown.enter.prevent="enterHandler"></b-form-input>
        <div
          class="input-clear-button"
          v-if="searchText != ''"
          @click="searchText = ''"
        > x
        </div>
      </div>
        <b-dropdown-form v-for="(item, index) in items" :key="index">
          <b-form-checkbox
            size="sm"
            @change="updateInput"
            v-model="items"
            name="check-button"
            :value="item"
          >
            {{ item }}
          </b-form-checkbox>
        </b-dropdown-form>
    </b-dropdown-form>
    
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-form>	
        <b-button class="no-border" size="sm"
        @click="closeFilter"
        >{{ $t("CLOSE") }}</b-button>
      <b-button class="no-border" size="sm"
      v-if="items.length != 0"
      @click="clearFilter"
      >{{ $t("CLEAR") }}</b-button>
      <b-button class="no-border" size="sm"
      v-if="searchText != ''"
      @click="addFilter"
      >{{ $t("ADD") }}</b-button>
    </b-dropdown-form>
  </b-dropdown>
</template>
<script>
export default {
  props: {
    filterData: {
      default: () => [],
      type: Array,
    },
    value: {
      type: Array,
      Default: [],
    },
    title: {
      default: "",
      type: String,
    }
  },
  data() {
    return {
      items: this.value,
      selected: [],
      searchText: "",
    };
  },
  methods: {
    enterHandler(){
      this.addFilter();
    },
    closeFilter() {
      this.$refs.downdownItem.visible = false;
    },
    clearFilter() {
      this.items = [];
      this.updateInput();
    },
    updateInput() {
      this.$emit("input", this.items);
    },
    addFilter(){
      this.items.push(this.searchText);
      this.searchText = "";
      this.updateInput();
    },
    getName(id) {
      let name = this.filterData.filter((item) => {
        return item.id == id;
      });
      if(name.length != 0)
      {
        name = name[0].text;
        return this.Truncate(name,12);
      }else{
        return this.title;
      }
      
    },
  },
  computed: {
    filterClass() {
      if (this.items.length != 0) {
        return ["filter-selected", "no-border"];
      } else {
        return ["no-border"];
      }
    },
    filterText() {
      if (this.items.length != 0) {
        if (this.items.length > 1) {
          return (
            this.title +
            ": " +
            this.items.slice().sort()[0] +
            " +(" +
            (this.items.length - 1) +
            ")"
          );
        } else {
          return this.title + ": " + this.items.slice().sort()[0];
        }
      } else {
        return this.title;
      }
    },
  },
  mounted() {},
};
</script>
<style></style>
