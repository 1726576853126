var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm.loa != 0
      ? _c("span", [
          _vm.loa == 1
            ? _c("span", { class: _vm.classinfo }, [
                _vm._v(_vm._s(_vm.loa1_text)),
              ])
            : _vm._e(),
          _vm.loa == 2
            ? _c(
                "span",
                { class: _vm.classinfo },
                [
                  _vm._v(_vm._s(_vm.loa2_text) + " - LOA2 "),
                  _vm.showInfo
                    ? _c("LoaLevel", { staticClass: "ml-2", attrs: { loa: 2 } })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.loa == 3
            ? _c(
                "span",
                { class: _vm.classinfo },
                [
                  _vm._v(_vm._s(_vm.loa3_text) + " - LOA3 "),
                  _vm.showInfo
                    ? _c("LoaLevel", { staticClass: "ml-2", attrs: { loa: 3 } })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.loa == 0
      ? _c(
          "div",
          { staticClass: "mt-2 ml-2" },
          [
            _vm.user.external_loa_levels.loa3
              ? _c(
                  "b-form-radio",
                  {
                    staticClass: "mt-2",
                    attrs: { value: 3 },
                    on: { change: _vm.changed },
                    model: {
                      value: _vm.loa_level,
                      callback: function ($$v) {
                        _vm.loa_level = $$v
                      },
                      expression: "loa_level",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.loa3_text) +
                        " - LOA3\n          "
                    ),
                    _c("LoaLevel", { staticClass: "ml-2", attrs: { loa: 3 } }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.user.loa_level_enabled.loa2_enabled
              ? _c(
                  "div",
                  [
                    _vm.user.external_loa_levels.loa2
                      ? _c(
                          "b-form-radio",
                          {
                            staticClass: "mt-2",
                            attrs: { value: 2 },
                            on: { change: _vm.changed },
                            model: {
                              value: _vm.loa_level,
                              callback: function ($$v) {
                                _vm.loa_level = $$v
                              },
                              expression: "loa_level",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.loa2_text) +
                                " - LOA2\n            "
                            ),
                            _c("LoaLevel", {
                              staticClass: "ml-2",
                              attrs: { loa: 2 },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.user.loa_level_enabled.loa1_enabled
              ? _c(
                  "div",
                  [
                    _vm.user.external_loa_levels.loa1
                      ? _c(
                          "b-form-radio",
                          {
                            staticClass: "mt-2",
                            attrs: { value: 1 },
                            on: { change: _vm.changed },
                            model: {
                              value: _vm.loa_level,
                              callback: function ($$v) {
                                _vm.loa_level = $$v
                              },
                              expression: "loa_level",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.loa1_text) +
                                " - LOA1\n            "
                            ),
                            _c("LoaLevel", {
                              staticClass: "ml-2",
                              attrs: { loa: 1 },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }