<template>
  <section v-if="items.length != 0">    
    <div class="info-header mt-2"><slot></slot></div>
        <div class="sdk-header-item" v-for="(item, index) in items" :key="index">
          <div class="information">
            <div class="label" v-b-tooltip.hover :title="getLabel(item)">
              {{ getLabel(item) }}
            </div>
            <div class="root" v-b-tooltip.hover :title="getExtensionValue(item)">
              {{ getRootName(item) }}:   {{ getExtensionValue(item) }}
            </div>        
          </div>
          <div class="remove" v-if="showAddRemove">
            <i
              class="fal fa-trash-alt pr-2 clickable"
              @click="removeItem(index)"
            ></i>
          </div>
        </div>
  </section>
</template>
<script>
export default {
  components: {},
  props: {  
    items: {
      type: Array,
      Default: []
    },
    isSender: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    showAddRemove: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    }
  },
  data() {
    return {
      label: "",
      extension: "",
      root: ""
    };
  },
  methods: {
    removeItem(index)
    {
      this.$emit('removeItem',index);
    },
    getLabel(item)
    {
      if(item.label)
      {
        return item.label;
      }else{
        return item.Label;
      }
    },
    getExtensionValue(item)
    {
      if(item.referenceId)
      {
        if(item.referenceId.root == "1.2.752.129.2.1.3.1")
        {
          return item.referenceId.extension.split('-')[0] + "-XXXX";
        }else{
          return item.referenceId.extension;
        }
      }else{
        if(item.ReferenceId.Root == "1.2.752.129.2.1.3.1")
        {
          return item.ReferenceId.Extension.split('-')[0] + "-XXXX";
        }else{
          return item.ReferenceId.Extension;
        }
      }
    },
    getRootName(item)
    {
      if(item.referenceId)
      {
        let rootInfo = item.referenceId.root.replaceAll(".","_");
        return this.$t("SDK.ITEMS." + rootInfo);
      }else{
        let rootInfo = item.ReferenceId.Root.replaceAll(".","_");
        return this.$t("SDK.ITEMS." + rootInfo);
      }
    }
  },
  computed: {},
  watch: {},
  mounted() {},
};
</script>
<style></style>