<template>
  <section>
    <b-form-input trim
      type="text"
      @input="checkValid"
      autocomplete="off"
      ref="phone"
      :state="valid"
      v-model="input_value"
    ></b-form-input>
  </section>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      Default: "",
    },
    required: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    exists: {
      type: Boolean,
      Default: false,
    }
  },
  data() {
    return {
      input_value: this.value
    };
  },
  computed: {
    valid()
    {
      if(this.required)
      {
        return this.validateEmail(this.input_value) && !this.exists;
      }else{
        if(!this.required && this.input_value == "")
        {
          return null; 
        }else{
          return this.validatePhone(this.input_value) && !this.exists;
        }
      }
    }
  },
  methods: {
    checkValid: function () {
      this.valid = this.validateEmail(this.input_value);
      this.$emit("valid", this.valid);
      this.$emit("input", this.input_value);
    },
  },
  mounted(){
    this.$emit("valid", this.valid);
  }
};
</script>
<style></style>