var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "compose-content" },
    [
      _c("p", [_vm._v(_vm._s(_vm.$t("SDK.EXTRA_INFO")))]),
      _c("p", [_vm._v(_vm._s(_vm.$t("SDK.EXTRA_INFO2")))]),
      _c(
        "AddSDKPersonId",
        {
          key: _vm.fromParticipantPersonsKey,
          attrs: { "is-sender": "", "show-add-remove": "" },
          model: {
            value: _vm.fromParticipantPersons,
            callback: function ($$v) {
              _vm.fromParticipantPersons = $$v
            },
            expression: "fromParticipantPersons",
          },
        },
        [_vm._v(_vm._s(_vm.$t("SDK.PERSONID.EXTENSION.SENDER.TITLE")))]
      ),
      _c(
        "AddSDKReferensId",
        {
          key: _vm.fromParticipantReferencesKey,
          attrs: { "is-sender": "", "show-add-remove": "" },
          model: {
            value: _vm.fromParticipantReferences,
            callback: function ($$v) {
              _vm.fromParticipantReferences = $$v
            },
            expression: "fromParticipantReferences",
          },
        },
        [_vm._v(_vm._s(_vm.$t("SDK.REFERENCEID.EXTENSION.SENDER.TITLE")))]
      ),
      _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
      _c(
        "AddSDKPersonId",
        {
          key: _vm.toParticipantPersonsKey,
          attrs: { "show-add-remove": "" },
          model: {
            value: _vm.toParticipantPersons,
            callback: function ($$v) {
              _vm.toParticipantPersons = $$v
            },
            expression: "toParticipantPersons",
          },
        },
        [_vm._v(_vm._s(_vm.$t("SDK.PERSONID.EXTENSION.RECIPIENT.TITLE")))]
      ),
      _c(
        "AddSDKReferensId",
        {
          key: _vm.toParticipantReferencesKey,
          attrs: { "show-add-remove": "" },
          model: {
            value: _vm.toParticipantReferences,
            callback: function ($$v) {
              _vm.toParticipantReferences = $$v
            },
            expression: "toParticipantReferences",
          },
        },
        [_vm._v(_vm._s(_vm.$t("SDK.REFERENCEID.EXTENSION.RECIPIENT.TITLE")))]
      ),
      _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
      _c(
        "b-button",
        {
          staticClass: "btn-fill",
          attrs: { variant: "primary" },
          on: { click: _vm.okBtn },
        },
        [_vm._v(_vm._s(_vm.$t("ADD")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }