var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items
    ? _c(
        "b-dropdown",
        {
          ref: "downdownItem",
          staticClass: "filter-dropdown",
          attrs: {
            id: "dropdown-form",
            size: "sm",
            text: _vm.filterText,
            "toggle-class": _vm.filterClass,
          },
        },
        [
          _c(
            "b-dropdown-form",
            [
              _c(
                "div",
                { staticClass: "input-clear mb-1" },
                [
                  _c("b-form-input", {
                    attrs: { trim: "", placeholder: _vm.$t("SEARCH") },
                    model: {
                      value: _vm.searchText,
                      callback: function ($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText",
                    },
                  }),
                  _vm.searchText != ""
                    ? _c(
                        "div",
                        {
                          staticClass: "input-clear-button",
                          on: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              $event.preventDefault()
                              return _vm.enterHandler.apply(null, arguments)
                            },
                            click: function ($event) {
                              _vm.searchText = ""
                            },
                          },
                        },
                        [_vm._v("\n        x\n      ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._l(_vm.filterItems, function (item, index) {
                return _c(
                  "b-dropdown-form",
                  { key: index },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          size: "sm",
                          name: "check-button",
                          value: item.id,
                        },
                        on: { change: _vm.updateInput },
                        model: {
                          value: _vm.items,
                          callback: function ($$v) {
                            _vm.items = $$v
                          },
                          expression: "items",
                        },
                      },
                      [
                        _vm._v("\n          " + _vm._s(item.text)),
                        item.description
                          ? _c("span", [
                              _vm._v(" "),
                              _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true },
                                  },
                                ],
                                staticClass: "float-right fal fa-info-circle",
                                attrs: { title: item.description },
                              }),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c("b-dropdown-divider"),
          _c(
            "b-dropdown-form",
            [
              _c(
                "b-button",
                {
                  staticClass: "no-border",
                  attrs: { size: "sm" },
                  on: { click: _vm.closeFilter },
                },
                [_vm._v(_vm._s(_vm.$t("CLOSE")))]
              ),
              _vm.filterItems.length != 0
                ? _c(
                    "b-button",
                    {
                      staticClass: "no-border",
                      attrs: { size: "sm" },
                      on: { click: _vm.clearFilter },
                    },
                    [_vm._v(_vm._s(_vm.$t("CLEAR")))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }