<template>
  <section class="compose-content">
    <p>{{ $t('SDK.EXTRA_INFO') }}</p>
    <p>{{ $t('SDK.EXTRA_INFO2') }}</p>
    
   <AddSDKPersonId :key="fromParticipantPersonsKey" v-model="fromParticipantPersons" is-sender show-add-remove
    >{{$t('SDK.PERSONID.EXTENSION.SENDER.TITLE')}}</AddSDKPersonId
    >
    <AddSDKReferensId :key="fromParticipantReferencesKey" v-model="fromParticipantReferences" is-sender show-add-remove
      >{{$t('SDK.REFERENCEID.EXTENSION.SENDER.TITLE')}}</AddSDKReferensId
    >
    
    <hr class="p-0 m-0 mt-2 mb-2" />

    <AddSDKPersonId :key="toParticipantPersonsKey" v-model="toParticipantPersons" show-add-remove
      >{{$t('SDK.PERSONID.EXTENSION.RECIPIENT.TITLE')}}</AddSDKPersonId
    >
    <AddSDKReferensId :key="toParticipantReferencesKey" v-model="toParticipantReferences" show-add-remove
      >{{$t('SDK.REFERENCEID.EXTENSION.RECIPIENT.TITLE')}}</AddSDKReferensId
    >
    <hr class="p-0 m-0 mt-2 mb-2" />

    <b-button
      @click="okBtn"
      variant="primary"
      class="btn-fill"
      >{{ $t("ADD") }}</b-button
    >
  </section>
</template>
<script>
import AddSDKPersonId from "../../Input/SDK/SdkAddPersonId";
import AddSDKReferensId from "../../Input/SDK/SdkAddReferensId";
export default {
  components: {
    AddSDKPersonId, AddSDKReferensId
  },
  props: {
    fromParticipantPersons: {
      default: () => [],
      type: Array,
    },
    fromParticipantReferences: {
      default: () => [],
      type: Array,
    },
    toParticipantPersons: {
      default: () => [],
      type: Array,
    },
    toParticipantReferences: {
      default: () => [],
      type: Array,
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    okBtn() {
      let obj = {
        fromParticipantPersons: this.fromParticipantPersons,
        fromParticipantReferences: this.fromParticipantReferences,
        toParticipantPersons: this.toParticipantPersons,
        toParticipantReferences: this.toParticipantReferences,
      }
      this.$emit('ok', obj);
    },
    cancelBtn() {
      this.$emit("cancel");
    }
  },
  computed: {
    toParticipantPersonsKey(){
      return "toParticipantPersonsKey_" + this.toParticipantPersons.length;
    },
    toParticipantReferencesKey(){
      return "toParticipantReferencesKey_" + this.toParticipantReferences.length;
    },
    fromParticipantPersonsKey(){
      return "fromParticipantPersonsKey_" + this.fromParticipantPersons.length;
    },
    fromParticipantReferencesKey(){
      return "fromParticipantReferencesKey_" + this.fromParticipantReferences.length;
    },
  },
  watch: {},
  mounted() {
  },
};
</script>
<style></style>
