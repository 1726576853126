<template>
     <b-dropdown
            id="dropdown-form"
            ref="downdownItem"
            class="filter-dropdown"
            size="sm"
            :text="filterText"
            :toggle-class="filterClass"
          >
            <b-dropdown-form>
              <b-form-input trim
              @keydown.enter.prevent="enterHandler"
                id="textarea"
                class="mb-2"                
                :placeholder="$t('SEARCH')"
                @input="updateInput"
                v-model="info"
                ></b-form-input>
            </b-dropdown-form>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-form>	

              <b-button class="no-border" size="sm"
              @click="closeFilter"
              >{{ $t("CLOSE") }}</b-button>
              <b-button class="no-border" size="sm"
              v-if="info.length != 0"
              @click="clearFilter"
              >{{ $t("CLEAR") }}</b-button>
            </b-dropdown-form>

          </b-dropdown>
  </template>
<script>
export default {
  props: ["value","title"],
  data() {
    return {      
      info: this.value
    };
  },
  methods: {
    closeFilter() {
      this.$refs.downdownItem.visible = false;
    },
    enterHandler(){
      return false;
    },
    clearFilter() {
      this.info = "";
      this.updateInput();
    },
    updateInput () {
      this.$emit('input', this.info)
    },
    getName()
    {
      
    }
  },
  computed: {
    filterItems(){
       return this.filterData.filter( (item) => {
          return (item.id.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1) || (item.text.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1)
      });
    },
    filterClass() {
      if (this.info != "") {
        return ["filter-selected", "no-border"];
      } else {
        return ["no-border"];
      }
    },
    filterText() {      
      return this.title + ": " + this.Truncate(this.info, 12);
    }
  },
  mounted(){    
  }
};
</script>
<style></style>
