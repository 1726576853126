var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("b-form-input", {
        staticClass: "mt-2 pl-2",
        attrs: {
          trim: "",
          "input-id": "subject",
          refs: "subject",
          id: "subject",
          tabindex: "0",
          disabled: _vm.settings.subject != "",
          placeholder: _vm.$t("SUBJECT"),
          state: _vm.TextState,
          autocomplete: "off",
        },
        model: {
          value: _vm.subject,
          callback: function ($$v) {
            _vm.subject = $$v
          },
          expression: "subject",
        },
      }),
      _c("div", { staticClass: "clearfix" }, [
        _c(
          "div",
          { staticClass: "float-left" },
          [
            _vm.subject.length > _vm.limit
              ? _c("div", { staticClass: "error-text" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("ERROR.TOO_MANY_CHARACTERS")) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
            _vm.settings.subject == "" && !_vm.hideDescription
              ? _c("b-form-text", { attrs: { id: "subject" } }, [
                  _c("i", { staticClass: "fa-light fa-lock-keyhole-open" }),
                  _vm._v(" " + _vm._s(_vm.$t("SUBJECT_EMAIL")) + "\n    "),
                ])
              : _vm._e(),
            _vm.settings.subject != "" && _vm.settings.unlock_subject
              ? _c(
                  "b-button",
                  {
                    staticClass: "no-border mt-2 mb-2",
                    attrs: { size: "sm" },
                    on: { click: _vm.unlockSubject },
                  },
                  [
                    _c("i", { staticClass: "fal fa-unlock" }),
                    _vm._v("\n      " + _vm._s(_vm.$t("BTN_UNLOCK_SUBJECT"))),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "float-right" }, [
          _c("div", { staticClass: "text-right" }, [
            _c("small", { staticClass: "pull-right" }, [
              _vm._v(_vm._s(_vm.subject.length) + "/" + _vm._s(_vm.limit)),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }