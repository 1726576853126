var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.details
    ? _c("section", [
        _c("div", { staticClass: "mt-2" }, [
          _vm._v(_vm._s(_vm.$t("INFORMATION"))),
        ]),
        _c(
          "div",
          { staticClass: "clearfix" },
          [
            _c(
              "b-table-simple",
              [
                _c(
                  "b-tbody",
                  [
                    _c(
                      "b-tr",
                      [
                        _c("b-td", { staticClass: "w-150" }, [
                          _vm._v(_vm._s(_vm.$t("NAME"))),
                        ]),
                        _c("b-td", [
                          _vm._v(_vm._s(_vm.details.attributes.name)),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-tr",
                      [
                        _c("b-td", { staticClass: "w-150" }, [
                          _vm._v(_vm._s(_vm.$t("DESCRIPTION"))),
                        ]),
                        _c("b-td", [
                          _vm._v(_vm._s(_vm.details.attributes.description)),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-tr",
                      [
                        _c("b-td", { staticClass: "w-150" }, [
                          _vm._v(_vm._s(_vm.$t("IDENTIFIER"))),
                        ]),
                        _c("b-td", [
                          _vm._v(_vm._s(_vm.details.attributes.identifier)),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-tr",
                      [
                        _c("b-td", { staticClass: "w-150" }, [
                          _vm._v(_vm._s(_vm.$t("ORGANISATION.TITLE"))),
                        ]),
                        _c("b-td", [
                          _vm._v(
                            _vm._s(_vm.details.relationships.parent.data.name)
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-tr",
                      [
                        _c("b-td", { staticClass: "w-150" }, [
                          _vm._v(_vm._s(_vm.$t("ORGANISATION.TITLE")) + " Id"),
                        ]),
                        _c("b-td", [
                          _vm._v(
                            _vm._s(
                              _vm.details.relationships.parent.data.identifier
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-tr",
                      [
                        _c("b-td", { staticClass: "w-150" }, [
                          _vm._v(_vm._s(_vm.$t("ID"))),
                        ]),
                        _c("b-td", [_vm._v(_vm._s(_vm.details.id))]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.details.attributes.categories
          ? _c("div", [
              _vm.details.attributes.categories.length != 0
                ? _c("div", [
                    _c("div", { staticClass: "mt-2" }, [
                      _vm._v(_vm._s(_vm.$t("SDK.CATEGORIES"))),
                    ]),
                    _vm.details.attributes.categories
                      ? _c(
                          "div",
                          { staticClass: "clearfix" },
                          [
                            _c(
                              "b-table-simple",
                              [
                                _c(
                                  "b-tbody",
                                  _vm._l(
                                    _vm.details.attributes.categories,
                                    function (item, index) {
                                      return _c(
                                        "b-tr",
                                        { key: index },
                                        [_c("b-td", [_vm._v(_vm._s(item))])],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.details.attributes.codes
          ? _c("div", { staticClass: "clearfix" }, [
              _vm.details.attributes.codes.length != 0
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "mt-2" }, [
                        _vm._v(_vm._s(_vm.$t("SDK.CODES"))),
                      ]),
                      1 == 0
                        ? _c("div", [_vm._v(_vm._s(_vm.$t("NO_CONTENT")))])
                        : _vm._e(),
                      _c(
                        "b-table-simple",
                        [
                          _c(
                            "b-tbody",
                            _vm._l(
                              _vm.details.attributes.codes,
                              function (item, index) {
                                return _c(
                                  "b-tr",
                                  { key: index },
                                  [
                                    _c("b-td", { staticClass: "w-150" }, [
                                      _vm._v(_vm._s(item.coding.code)),
                                    ]),
                                    _c("b-td", [
                                      _vm._v(_vm._s(item.coding.displayText)),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.details.attributes.municipalityCode
          ? _c("div", { staticClass: "clearfix" }, [
              _vm.details.attributes.municipalityCode.length != 0
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "mt-2" }, [
                        _vm._v(_vm._s(_vm.$t("SDK.MUNICIPALITIES"))),
                      ]),
                      _c(
                        "b-table-simple",
                        [
                          _c(
                            "b-tbody",
                            _vm._l(
                              _vm.details.attributes.municipalityCode,
                              function (item, index) {
                                return _c(
                                  "b-tr",
                                  { key: index },
                                  [
                                    _c("b-td", { staticClass: "w-150" }, [
                                      _vm._v(_vm._s(item.coding.code)),
                                    ]),
                                    _c("b-td", [
                                      _vm._v(_vm._s(item.coding.displayText)),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.details.attributes.regionCode
          ? _c("div", { staticClass: "clearfix" }, [
              _vm.details.attributes.regionCode.length != 0
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "mt-2" }, [
                        _vm._v(_vm._s(_vm.$t("SDK.REGIONS"))),
                      ]),
                      _c(
                        "b-table-simple",
                        [
                          _c(
                            "b-tbody",
                            _vm._l(
                              _vm.details.attributes.regionCode,
                              function (item, index) {
                                return _c(
                                  "b-tr",
                                  { key: index },
                                  [
                                    _c("b-td", { staticClass: "w-150" }, [
                                      _vm._v(_vm._s(item.coding.code)),
                                    ]),
                                    _c("b-td", [
                                      _vm._v(_vm._s(item.coding.displayText)),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.loaded_address
          ? _c(
              "div",
              { staticClass: "mt-2 mb-2" },
              [
                !_vm.hideSelect
                  ? _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "dark" },
                        on: { click: _vm.selectedAddress },
                      },
                      [_vm._v(_vm._s(_vm.$t("CHOOSE")))]
                    )
                  : _vm._e(),
                !_vm.hideSave && _vm.show_save && _vm.userCanEdit
                  ? _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "primary" },
                        on: { click: _vm.saveAddress },
                      },
                      [
                        _c("i", {
                          staticClass: "fa-sharp fa-regular fa-bookmark",
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("SDK.SAVE_RECIPIENT"))),
                      ]
                    )
                  : _vm._e(),
                !_vm.hideRemove && !_vm.show_save && _vm.userCanEdit
                  ? _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "danger" },
                        on: { click: _vm.removeAddress },
                      },
                      [
                        _c("i", {
                          staticClass: "fa-sharp fa-solid fa-bookmark",
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("SDK.REMOVE_RECIPIENT"))),
                      ]
                    )
                  : _vm._e(),
                !_vm.hideBack
                  ? _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "secondary" },
                        on: { click: _vm.backBtn },
                      },
                      [_vm._v(_vm._s(_vm.$t("BACK")))]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }